/* App specific */
.Main-content { max-width: 800px; }

/* Colors & Backgrounds */
.background-smoke { background-color: darkslategrey;}

/* Margin & Padding */
.margin-x--xs {
  margin-left: .4rem;
  margin-right: .4rem;
}
.margin-x--sm {
  margin-left: 1rem;
  margin-right: 1rem;
}
.margin-y--sm { margin-top: 1rem; }

/* Display */
.display-none, .screen-reader-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

/* Flex */
.display-flex { display: flex; }
.flex-column { flex-direction: column; }
.align-items-center { align-items: center; }
.justify-content-center { justify-content: center; }

/* Height & Width */
.height-100 { height: 100%; }
.height-100vh { height: 100vh; }

/* Util */
.no-underline { text-decoration: none; }
